import { DetailsList, IColumn, SelectionMode, } from '@fluentui/react';
import React from 'react';
import { IItem } from './IListData';
import { initializeIcons } from '@uifabric/icons';
import { Icon } from '@fluentui/react/lib/Icon';
import { Callout } from '@fluentui/react';  
import { Link } from '@fluentui/react';  


/* #region Interfaces */
export interface IListComponentProps {
    property?: any;
}
export interface IListComponentState {
    columns: IColumn[];
    sortedItems: IItem[];
    isCalloutVisible?: boolean;
}
/* #endregion */
export default class ListComponent extends React.Component<{}, IListComponentState>{
    
    _menuButtonElement?: HTMLElement;
    private theData: IItem[];
    private columns: IColumn[];
    //private _selection!: Selection;

    constructor(props: {}) {
        super(props);

        this._onShowMenuClicked = this._onShowMenuClicked.bind(this); 
        this._onCalloutDismiss = this._onCalloutDismiss.bind(this); 

        //initializeIcons(); //https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
        /* #region Variante*/
        //Data mit umfangreicher Typisierung
        /* this.myData = [
            {field1 :{fieldIndex:0, fieldContent:'n1'},field2:{fieldIndex:1, fieldContent:"n2"},field3:{fieldIndex:2, fieldContent:30},fieldArray:[{ fieldIndex: 10, fieldContent: 'a' },{fieldIndex: 11, fieldContent:'b'},{fieldIndex:12,fieldContent:'c'}], status:status.offen, url: {fieldIndex:11, fieldContent:'WiKi',linkDescr:"WiKi", linkUrl:"https://wiki.swinks.de"}},
            {field1 :{fieldIndex:0, fieldContent:'a1'},field2:{fieldIndex:1, fieldContent:"a2"},field3:{fieldIndex:2, fieldContent:30},fieldArray:[{ fieldIndex: 10, fieldContent: 'x' },{fieldIndex: 11, fieldContent:'y'},{fieldIndex:12,fieldContent:'z'}], status:status.offen, url: {fieldIndex:11, fieldContent:'Cloud',linkDescr:"Cloud", linkUrl:"https://cloud.swinks.de"}},
            {field1 :{fieldIndex:0, fieldContent:'b1'},field2:{fieldIndex:1, fieldContent:"b2"},field3:{fieldIndex:2, fieldContent:30},fieldArray:[{ fieldIndex: 10, fieldContent: 'x' },{fieldIndex: 11, fieldContent:'y'},{fieldIndex:12,fieldContent:'z'}], status:status.offen, url: {fieldIndex:11, fieldContent:'Kanban',linkDescr:"Kanban", linkUrl:"https://kanboard.swinks.de"}},
            {field1 :{fieldIndex:0, fieldContent:'c1'},field2:{fieldIndex:1, fieldContent:"c2"},field3:{fieldIndex:2, fieldContent:30},fieldArray:[{ fieldIndex: 10, fieldContent: 'x' },{fieldIndex: 11, fieldContent:'y'},{fieldIndex:12,fieldContent:'z'}], status:status.offen, url: {fieldIndex:11, fieldContent:'Zeiterfassung',linkDescr:"Zeiterfassung", linkUrl:"https://kimai.swinks.de"}},
            {field1 :{fieldIndex:0, fieldContent:'d1'},field2:{fieldIndex:1, fieldContent:"d2"},field3:{fieldIndex:2, fieldContent:30},fieldArray:[{ fieldIndex: 10, fieldContent: 'x' },{fieldIndex: 11, fieldContent:'y'},{fieldIndex:12,fieldContent:'z'}], status:status.offen, url: {fieldIndex:11, fieldContent:'Winfoto',linkDescr:"Winfoto", linkUrl:"https://winfoto.de"}},
        ]; */
        /* #endregion */
        //Data mit simpler Typisierung
        this.theData = [
            { ftype: "string", field1: 'a1', field2: "a2", field3: 39, fieldArray: ['x', 'y', 'z'], status: { sName: "offen", sIcon: "OpenEnrollment" }, url: { linkDescr: "Cloud", linkUrl: "https://cloud.swinks.de" } },
            { ftype: "string", field1: 'b1', field2: "b2", field3: 35, fieldArray: ['x', 'y', 'z'], status: { sName: "in Bearbeitung", sIcon: "EditSolid12" }, url: { linkDescr: "Kanban", linkUrl: "https://kanboard.swinks.de" } },
            { ftype: "number", field1: 'c1', field2: "c2", field3: 37, fieldArray: ['x', 'y', 'z'], status: { sName: "zur Freigabe", sIcon: "DocumentApproval" }, url: { linkDescr: "Zeiterfassung", linkUrl: "https://kimai.swinks.de" } },
            { ftype: "url", field1: 'd1', field2: "d2", field3: 33, fieldArray: ['x', 'y', 'z'], status: { sName: "Freigegeben", sIcon: "BoxCheckmarkSolid" }, url: { linkDescr: "Winfoto", linkUrl: "https://winfoto.de" } },
        ]

        this.columns = [
            { key: 'col0', name: 'ICON', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column) },
            { key: 'col1', name: 'Spalte 1', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column) },
            { key: 'col2', name: 'Spalte 2', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column), },
            { key: 'col3', name: 'Spalte 3', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column), },
            { key: 'col4', name: 'Spalte 4', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column) },
            { key: 'col5', name: 'Status', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column) },
            { key: 'col6', name: 'URL', isResizable: true, minWidth: 10, maxWidth: 80, onRender: (item: IItem, index, column: IColumn | undefined) => this._onRender(item, index, column) },
        ]

        // Icons für Status anzeigen
        // Sortieren und gruppieren
        // add und delete
        // Buttons und Kontext-Menü

        this.state = {
            //items: this.theData,
            sortedItems: this.theData,
            columns: this.columns,
            isCalloutVisible: false,

        };

    };

    public render() {
        const { isCalloutVisible, sortedItems, columns } = this.state;
        return (
            <div>
            <DetailsList
                items={sortedItems}
                setKey="set"
                columns={columns}
                selectionMode={SelectionMode.none}
                onColumnHeaderClick={this._onColumnClick}
            // onRenderItemColumn={_renderItemColumn} // generisches column render
            />
            { isCalloutVisible && ( 
          <Callout 
            className='ms-CalloutExample-callout'  
            ariaLabelledBy={ 'callout-label-1' }  
            ariaDescribedBy={ 'callout-description-1' }  
            role={ 'alertdialog' }  
            gapSpace={ 0 } 
            //targetElement={ this._menuButtonElement } 
            onDismiss={ this._onCalloutDismiss } 
            setInitialFocus={ true } 
          > 
            <div className='ms-CalloutExample-header'>  
              <p className='ms-CalloutExample-title' id={ 'callout-label-1' }> 
                All of your favorite people 
              </p> 
            </div> 
            <div className='ms-CalloutExample-inner'>  
              <div className='ms-CalloutExample-content'>  
                <p className='ms-CalloutExample-subText' id={ 'callout-description-1' }> 
                  Message body is optional. If help documentation is available, consider adding a link to learn more at the bottom. 
                </p> 
              </div> 
              <div className='ms-CalloutExample-actions'>  
                <Link className='ms-CalloutExample-link' href='http://microsoft.com'>Go to microsoft</Link> 
              </div> 
            </div> 
          </Callout> 
        ) } 
            </div>
        )};

    private _onShowMenuClicked() { 
            this.setState({ 
              isCalloutVisible: !this.state.isCalloutVisible 
            }); 
          } 
        
    private _onCalloutDismiss() { 
            this.setState({ 
              isCalloutVisible: false 
            }); 
          } 
    

    private _onColumnClick = (_event?: React.MouseEvent<HTMLElement>, column?: IColumn): void => {
        alert(column?.name + " / " + column?.key)
        const { columns } = this.state;
        let { sortedItems } = this.state;
        let isSortedDescending = column!.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column!.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        sortedItems = _copyAndSort(sortedItems, column!.fieldName!, isSortedDescending);

        // Reset the items and columns to match the state.
        this.setState({
            sortedItems: sortedItems,
            columns: columns.map(col => {
                col.isSorted = col.key === column!.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            }),
        });
    };




    private _onRender(item: IItem, index: number | undefined, column: IColumn | undefined) {

        var retval: any;

        switch (column!.name) {
            case "ICON": {
                initializeIcons();
                const MyIcon = () => <Icon iconName={item.status?.sIcon} />;
                retval = <a title={item.status?.sName}><MyIcon /></a>
                break;
            }
            case "Spalte 1": {
                retval = <div>{item.field1}</div>
                break;
            }
            case "Spalte 2": {
                retval = <div  onClick={this._onShowMenuClicked}>{item.field2}</div>
                break;
            }
            case "Spalte 3": {
                retval = <div>{item.field3}</div>
                break;
            }
            case "Spalte 4": {
                retval = item.fieldArray?.map((element) => <li>{element}</li>)
                break;
            }
            //Status durch icon ersetzen
            case "Status": {

                retval = <div>{item.status?.sName}</div>
                break;
            }
            case "URL": {
                retval = <a href={item.url?.linkUrl} target="_blank" >{item.url?.linkDescr}</a>
                break;
            }

        }

        return retval;
    }
}




function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

